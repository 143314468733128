.MuiChartsAxis-tickLabel{
	font-size:9px !important;
}

.MuiPopper-root,
.MuiChartsTooltip-root,
.MuiChartsTooltip-markCell,
.MuiChartsTooltip-labelCell,
.MuiChartsTooltip-valueCell{
	z-index: 100000;
}